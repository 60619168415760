<template>
	<div>
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit"> {{ $language.cartel_new.title_subscribe_management }} <!-- 구독 관리--> </h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-80">
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_box_1" @click="toPlane">
						<div class="color-gray size-px-13">{{ $language.cartel_new.txt_plan1 }} <!-- 다양한 구독 플랜을 구성하세요. --></div>
						<h2 class="mt-20 font-weight-400">{{ $language.subscribe.title_subscribe_plane_management }} <!-- 구독 플랜 관리 --></h2>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1" @click="toCalculate">
						<div class="color-gray size-px-13">{{ $language.cartel_new.txt_calculate }} <!-- 구독 정산을 받으세요. --></div>
						<h2 class="mt-20 font-weight-400">{{ $language.cartel_new.title_calculate }} <!-- 구독 정산 --> </h2>
					</div>
				</div>
			</div>
			<div
				class="subindex_item"
			>
				<div class="container">
					<div class="subindex_box_1" @click="toSubscribeMember">
						<div class="color-gray size-px-13">{{ $language.subscribe.txt_subscribe_member_management }} <!-- 구독 중인 멤버를 관리하세요 --></div>
						<h2 class="mt-20 font-weight-400">{{ $language.subscribe.title_subscribe_member_management }} <!-- 구독자 관리 --></h2>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SubscribeManagement'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.notice.add
				, title: this.$language.notice.add
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'

			}
			, item_cartel: {}
			, item_board_config: {
				board_name: ''
				, cartl_number: this.$route.params.idx
				, member_number: this.user.member_number
				, board_release_fg: 'Y'
				, admin_subscrp_board_mangement_authority_fg: 'Y'
			}
		}
	}
	, computed: {
		is_save: function(){
			let t = true
			if(this.item_board_config.board_name != ''){
				t = false
			}

			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toPlane: function(){
			this.$bus.$emit('to',{ name: 'SubscribePlane', params: { idx: this.$route.params.idx }})
		}
		, toCalculate: function(){

		}
		, toMember: function(){

		}
		, goBack: function(){
			this.$bus.$emit('goBack')
		}
		, toSubscribeMember: function(){
			this.$bus.$emit('to', { name: 'SubscribeMember', params: { idx: this.$route.params.idx }})
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
	}
}
</script>

<style>
.color-primary { color: var(--blue01) !important;}
</style>